<template>
  <div>
    <NavigationBar titel="Konfiguration" isPreview></NavigationBar>
    <h1>Eigene:</h1>
    <p>Textvorlagen/-bausteine, Genehmigungsprozesse</p>
    <h1>Von MonkeyOffice</h1>
    <p>Buchungsvorlagen, Nummernkreise</p>
    <h1>Von Odoo</h1>
    <p>
      Bankkonten, Geschäftsjahre, Kontenplan, Steuern, Journale, Währungen,
      Steuerzuordnung
    </p>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
export default {
  name: "finance-config",
  components: {
    NavigationBar,
  },
};
</script>
